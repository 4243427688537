import React, { Fragment } from "react";
import { Layout, SEO } from "src/components";
import {
  ActionButtonGroupTemplate,
  HeroTemplate,
  MainStorySectionTemplate,
  SecondaryStorySectionTemplate,
  PressKitTemplate,
  CarouselStorySectionTemplate
} from "src/services/prismic/templates";
import { graphql } from "gatsby";
import { PrismicPage } from "src/graphqlTypes";

type PressProps = {
  data: {
    prismicPage: PrismicPage;
  };
};

const Press = ({ data: { prismicPage: page } }: PressProps) => {
  return (
    <Fragment>
      <SEO
        title={page.data?.title.text}
        description={page.data?.description.text}
        path="/story"
      />

      <Layout>
        {page?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyPrimarystorysection":
              return <MainStorySectionTemplate data={slice} key={idx} />;
            case "PrismicPageBodyActionButtonGroup":
              return <ActionButtonGroupTemplate data={slice} key={idx} />;
            case "PrismicPageBodySecondaryStorySection":
              return <SecondaryStorySectionTemplate data={slice} key={idx} />;
            case "PrismicPageBodyStoryCarouselHorizontal":
              return <CarouselStorySectionTemplate data={slice} key={idx} />;
            case "PrismicPageBodyPressKit":
              return <PressKitTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "story" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...PrismicPrimaryStorySection
          ...PrismicActionButtonGroup
          ...PrismicSecondaryStorySection
          ...PrismicCarouselStorySection
          ...PrismicPressKit
        }
      }
    }
  }
`;

export default Press;
